<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission'
import LikeButton from '@/Components/Global/LikeButton.vue'
import ShareModal from '@/Components/Global/ShareModal.vue'
import GameFollowButton from '@/Components/Game/GameFollowButton.vue'
import type { Game } from '@/Types/Models/Game'
import { onMounted, type PropType, ref } from 'vue'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePermissions } from '@/Composables/usePermissions'
import { useCountGameLikes } from '@/Composables/useCountGameLikes.ts'

const props = defineProps({
    game: Object as PropType<Game>,
    light: {
        type: Boolean,
        default: false
    },
    small: {
        type: Boolean,
        default: false
    },
    share: {
        type: Boolean,
        default: false
    },
    initialLikesCount: [Number, null],
    initialCommentsCount: [Number, null]
})

const likesCount = ref<Number>(props.game.likes_count)

function getLikesCount () {
    useCountGameLikes(props.game).then(function (result) {
        likesCount.value = result
    })
}

onMounted(() => {
    if (
        !props.small &&
        (props.initialLikesCount === null || props.initialLikesCount === undefined)
    ) {
        getLikesCount()
    } else {
        likesCount.value = props.initialLikesCount
    }
})

</script>

<template>
    <div
        class="flex items-center gap-2"
        :class="[small ? '' : 'xxs:gap-4']">
        <LikeButton
            :key="likesCount"
            :light="light"
            :can="usePermissions(UserPermission.LIKE_GAME)"
            :count="likesCount"
            :liked="game.liked"
            :show-count="!small"
            :small="small"
            :url="useRoute('game.like.toggle', {game: game.slug})"
            like-tooltip="Like game"
            unlike-tooltip="Unlike game" />

        <div class="flex shrink-0">
            <GameFollowButton
                :icon-only="small"
                :small="small"
                :game="game" />
        </div>

        <div v-if="share">
            <ShareModal
                :small
                icon-only="mobile"
                :url="useRoute('games.show', {game: game.slug})"
                :title="game.name"
                :excerpt="game.description" />
        </div>
    </div>
</template>
