<script setup lang="ts">
import Icon from '@/Components/Icon/index.js'
import TagList from '@/Components/Tag/TagList.vue'
import Breadcrumbs from '@/Components/UI/Breadcrumbs.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import TagFollowButton from '@/Components/Tag/TagFollowButton.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import type { Tag } from '@/Types/Models/Tag'
import { computed, ref, type PropType } from 'vue'
import type { PageHeader } from '@/Types/PageHeader'
import { useHeader } from '@/Composables/useHeader.ts'
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'
import {
    TagIcon as TagIconSolid,
    ArrowTrendingUpIcon,
    SparklesIcon,
    FireIcon,
    BookmarkIcon,
    UserGroupIcon,
    RssIcon
} from '@heroicons/vue/24/solid'

// Define the component props
const props = defineProps({
    header: Object as PropType<PageHeader>,
    hasRelatedTags: Boolean,
    truncateDescription: {
        type: Boolean,
        default: true
    },
    icon: {
        type: [Function, null],
        default: null
    },
    relatedTags: [Array, null] as PropType<Tag[]>
})

// Breakpoints configuration
const breakpoints = useBreakpoints(breakpointsTailwind)
const smAndLarger = breakpoints.greaterOrEqual('sm')

// Map of icons
const iconMap = {
    ArrowTrendingUpIcon,
    FireIcon,
    BookmarkIcon,
    UserGroupIcon,
    RssIcon,
    TagIconSolid,
    GamePadFilled: Icon.GamePadFilled,
    SparklesIcon,
    GamePad: Icon.GamePad
}

// Determine the icon component based on the header icon
const iconComponent = computed(() => {
    if (props.icon) {
        return props.icon
    }

    if (props.header?.icon && typeof props.header.icon === 'string' && props.header.icon.startsWith('component:')) {
        const componentName = props.header.icon.replace('component:', '')
        return iconMap[componentName]
    }
    return null
})

// Background color and gradient setup
const backgroundColour = useHeader('background', props.header)
const gradient = computed(() => backgroundColour && backgroundColour === 'var(--colors-primary)')

// Track whether the description is expanded
const descriptionLength = ref(120)
const descriptionClicked = ref(false)
const description = computed(() => {
    return (descriptionClicked.value || !props.truncateDescription)
        ? props.header.description
        : truncate(props.header.description, descriptionLength.value)
})

function truncate (text: string, length: number): string {
    if (text.length > length && props.truncateDescription) {
        return `${text.substring(0, length)}...`
    }
    return text
}
</script>

<template>
    <div class="p-5 md:p-4 container md:max-w-[unset]">
        <div
            v-if="header?.title"
            :class="[
                useHeader('text', header),
                gradient ? 'bg-gradient-app bg-gradient-to-t' : ''
            ]"
            :style="{ backgroundColor: `${useHeader('background', header)}` }"
            class="relative py-8 md:py-18 mb-8 shadow-sm rounded-xl">
            <div class="container relative mx-auto">
                <div class="flex flex-col items-start gap-2 xs:flex-row xs:items-center xs:justify-between xs:gap-0">
                    <div>
                        <Breadcrumbs
                            v-if="header?.breadcrumbs"
                            class="mb-4"
                            :breadcrumbs="header?.breadcrumbs" />
                        <SectionTitle
                            font-size="text-lg xs:text-2xl md:text-4xl"
                            class="mx-auto flex items-center tracking-tight">
                            <div
                                v-if="useHeader('icon', header) || iconComponent"
                                :class="[
                                    useHeader('iconBackground', header),
                                ]"
                                :style="{ color: `${useHeader('iconText', header)}` }"
                                class="mr-2 flex aspect-square w-10 items-center justify-center rounded-lg shadow-sm p-1">
                                <span v-if="useHeader('icon', header)">
                                    <img
                                        class="mx-auto w-7"
                                        :alt="`Icon for page`"
                                        :src="useHeader('icon', header)"
                                        width="36">
                                </span>
                                <component
                                    :is="iconComponent"
                                    v-if="iconComponent"
                                    class="w-7" />
                            </div>
                            <div class="break-words hyphens-auto">
                                {{ header?.title }}
                            </div>
                        </SectionTitle>
                        <!--                        <p-->
                        <!--                            v-if="header?.description"-->
                        <!--                            class="mt-2 block prose text-sm xs:hidden text-inherit">-->
                        <!--                            {{ description }}-->
                        <!--                            <button-->
                        <!--                                v-if="truncateDescription && header.description.length > descriptionLength"-->
                        <!--                                class="inline-flex font-semibold hover:underline"-->
                        <!--                                @click="descriptionClicked = !descriptionClicked">-->
                        <!--                                {{ descriptionClicked ? "Read less" : "Read more" }}-->
                        <!--                            </button>-->
                        <!--                        </p>-->
                    </div>
                    <div
                        v-if="header.tag"
                        class="shrink-0">
                        <TagFollowButton
                            :padding="!smAndLarger ? 'px-2 py-1' : 'px-4 py-2'"
                            :tag="header.tag" />
                    </div>
                </div>

                <!--                <p-->
                <!--                    v-if="header?.description"-->
                <!--                    class="mt-2 hidden max-w-2xl prose text-sm text-inherit text-pretty xs:block">-->
                <!--                    {{ description }}-->
                <!--                    <button-->
                <!--                        v-if="truncateDescription && header.description.length > descriptionLength"-->
                <!--                        class="inline-flex font-semibold hover:underline"-->
                <!--                        @click="descriptionClicked = !descriptionClicked">-->
                <!--                        {{ descriptionClicked ? "Read less" : "Read more" }}-->
                <!--                    </button>-->
                <!--                </p>-->

                <div
                    v-if="hasRelatedTags"
                    class="mt-2">
                    <TagList
                        :small="true"
                        :center="false"
                        :tags="relatedTags" />
                </div>
            </div>
        </div>
    </div>
</template>
