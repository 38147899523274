<script setup lang="ts">
import Header from '@/Components/UI/Header.vue'
import CategoryIconMap from '@/Configs/CategoryIconMap.ts'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import EmptyStateAlt from '@/Components/Global/EmptyStateAlt.vue'
import ArticleFilter from '@/Components/Article/ArticleFilter.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import type { Tag } from '@/Types/Models/Tag'
import { computed, type PropType, ref } from 'vue'
import { Deferred, router } from '@inertiajs/vue3'
import { usePlural } from '@/Composables/usePlural'
import type { PageHeader } from '@/Types/PageHeader'
import type { Category } from '@/Types/Models/Category'
import { useSingular } from '@/Composables/useSingular.js'

const props = defineProps({
    articles: [Array, Object] as PropType<any>,
    header: Object as PropType<PageHeader>,
    orderable: Boolean,
    orderData: Object,
    orderRoute: String,
    category: [Object as PropType<Category>, null],
    relatedTags: [Array, null] as PropType<Tag[]>,
    hasRelatedTags: {
        type: Boolean,
        default: false
    }
})

const allRelatedTags = ref(props.relatedTags)

const displaySortingOptions = computed(() => {
    return props.orderable && props.orderData && props.orderRoute &&
        (props.articles?.length > 0 || props.articles?.data.length > 0)
})

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <div class="pb-12">
        <Header
            :icon="category ? CategoryIconMap[category.title] : null"
            :has-related-tags="hasRelatedTags"
            :related-tags="allRelatedTags"
            :header="header" />

        <div
            v-if="displaySortingOptions"
            class="container mx-auto">
            <div class="flex items-center justify-between">
                <SectionTitle
                    v-if="displaySortingOptions"
                    class="mr-8 shrink-0">
                    Articles
                </SectionTitle>

                <ArticleFilter
                    v-if="displaySortingOptions"
                    :data="orderData"
                    :route-name="orderRoute" />
            </div>
        </div>

        <Deferred data="articles">
            <template #fallback>
                <ArticleSkeletonGrid :number="8" />
            </template>

            <ArticleGrid
                contain
                :skeleton-count="8"
                :articles="articles">
                <template #empty>
                    <EmptyStateAlt
                        :description="`There are no published articles here. Try writing your own <em class='lowercase'>${header?.title ? useSingular(header?.title) : ''}</em> article today!`">
                        <template #button>
                            <ArticleCreateButton
                                :append="header?.tag ? { tag: header?.tag.name } : null"
                                text="Write your own" />
                        </template>
                    </EmptyStateAlt>
                </template>
            </ArticleGrid>
        </Deferred>
    </div>
</template>
