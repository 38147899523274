<script setup>
import Icon from '@/Components/Icon/index.js'
import Spinner from '@/Components/UI/Spinner.vue'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import Transition from '@/Components/Transition/index.js'
import ModalBackdrop from '@/Components/UI/ModalBackdrop.vue'
import ModalContainer from '@/Components/UI/ModalContainer.vue'
import ArticleUserLink from '@/Components/Article/ArticleUserLink.vue'
import ArticleThumbnail from '@/Components/Article/ArticleThumbnail.vue'
import GameCardHorizontal from '@/Components/Game/GameCardHorizontal.vue'
import { debounce } from 'lodash'
import { router, Link } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { useActiveElement, useMagicKeys } from '@vueuse/core'
import { useFormatUsername } from '@/Composables/useFormatUsername.js'
import { ref, computed, onMounted, nextTick, onUnmounted, watch } from 'vue'
import {
    MagnifyingGlassIcon,
    ChevronLeftIcon
} from '@heroicons/vue/20/solid'

const props = defineProps({
    show: {
        type: Boolean,
        default: false
    }
})

const emit = defineEmits(['close', 'open'])

const resultInputRef = ref(null)
const resultInputRefContainer = ref(null)
const resultInput = ref('')
const results = ref([])
const selectedResultIndex = ref(-1)
const loading = ref(false)
const error = ref(null)

const fetchResults = async (query) => {
    loading.value = true
    error.value = null
    try {
        const response = await fetch(useRoute('api.search', { q: query }))
        if (!response.ok) {
            throw new Error('Something went wrong')
        }
        results.value = await response.json()
        selectedResultIndex.value = -1
    } catch (err) {
        error.value = err.message
    } finally {
        loading.value = false
    }

    // results.value.unshift(
    //     {
    //         type: 'results',
    //         url: useRoute('search.index', { q: query }),
    //         title: 'See all results'
    //     }
    // )
}

const debouncedFetchResults = debounce(fetchResults, 300)

watch(resultInput, (value) => {
    if (value.trim()) {
        debouncedFetchResults(value)
    } else {
        results.value = []
    }
})

// eslint-disable-next-line sonarjs/cognitive-complexity
const navigate = (action) => {
    if (action === 'up') {
        selectedResultIndex.value = selectedResultIndex.value > 0 ? selectedResultIndex.value - 1 : results.value.length - 1
    } else if (action === 'down') {
        selectedResultIndex.value = selectedResultIndex.value < results.value.length - 1 ? selectedResultIndex.value + 1 : 0
    } else if (action === 'enter') {
        if (selectedResultIndex.value === -1) {
            router.get(useRoute('search.index', { q: resultInput.value }))
        } else {
            selectResult(selectedResultIndex.value)
        }

        setTimeout(() => close(), 150)
    }

    nextTick(() => {
        const resultList = resultInputRefContainer.value.nextElementSibling
        const selectedElement = resultList.children[selectedResultIndex.value]

        if (selectedElement) {
            const listRect = resultList.getBoundingClientRect()
            const selectedRect = selectedElement.getBoundingClientRect()

            if (selectedRect.top < listRect.top) {
                // Item is above the visible area
                resultList.scrollTop -= listRect.top - selectedRect.top
            } else if (selectedRect.bottom > listRect.bottom) {
                // Item is below the visible area
                resultList.scrollTop += selectedRect.bottom - listRect.bottom
            }
        }
    })
}

const selectResult = (index) => {
    selectedResultIndex.value = index
    executeResult()
}

const executeResult = () => {
    if (selectedResultIndex.value !== -1) {
        const selected = results.value[selectedResultIndex.value]
        router.get(selected.url)
    }
}

const filteredResults = computed(() => results.value)

const keys = useMagicKeys({
    passive: true
})
const cmdK = keys['Meta+K']
const activeElement = useActiveElement()
const notUsingInput = computed(() =>
    activeElement.value?.tagName !== 'INPUT' &&
    activeElement.value?.tagName !== 'TEXTAREA'
)

watch(cmdK, (value) => {
    if (value && notUsingInput.value) {
        emit('open')
    }
})

onMounted(() => {
    document.addEventListener('keydown', closeOnEscape)
})

watch(() => props.show, () => {
    if (props.show) {
        document.body.style.overflow = 'hidden'
        setTimeout(() => {
            resultInputRef.value.focus()
        }, 100)
    } else {
        document.body.style.overflow = null
    }
})

const close = () => {
    if (props.show) {
        resultInput.value = ''
        results.value = []
        selectedResultIndex.value = -1
        loading.value = false
        error.value = null
        emit('close')
    }
}

const closeOnEscape = (e) => {
    if (e.key === 'Escape' && props.show) {
        close()
    }
}

onUnmounted(() => {
    document.removeEventListener('keydown', closeOnEscape)
    document.body.style.overflow = null
})

router.on('navigate', close)
</script>

<template>
    <teleport to="body">
        <Transition leave-active-class="duration-200">
            <div
                v-show="show"
                class="fixed inset-0 z-50 overflow-y-auto px-0 sm:py-6 md:py-24"
                scroll-region>
                <ModalBackdrop
                    :show="show"
                    @click="close" />

                <Transition.FadeUp>
                    <ModalContainer
                        v-if="show"
                        class="max-w-3xl min-h-[75dvh] sm:min-h-0">
                        <div class="relative z-50">
                            <div
                                ref="resultInputRefContainer"
                                class="relative group">
                                <input
                                    ref="resultInputRef"
                                    :value="resultInput"
                                    type="text"
                                    class="w-full border-b border-zinc-200 p-2 px-3 py-3 pr-32 pl-12 transition-colors focus:outline-none dark:bg-slate-900 sm:text-sm"
                                    placeholder="Search..."
                                    @input="resultInput = $event.target.value"
                                    @keydown.esc.prevent="close"
                                    @keydown.up.prevent="navigate('up')"
                                    @keydown.down.prevent="navigate('down')"
                                    @keydown.enter.prevent="navigate('enter')"
                                >
                                <div
                                    class="absolute top-1/2 left-4 z-50 flex -translate-y-1/2 items-center justify-center text-slate-900/30 group-focus-within:text-slate-900/50 transition-colors dark:group-focus-within:text-white dark:text-white/75">
                                    <MagnifyingGlassIcon class="hidden w-5 md:flex" />
                                    <button
                                        class="md:hidden"
                                        @click="close">
                                        <ChevronLeftIcon class="w-5" />
                                    </button>
                                </div>
                                <Link
                                    v-if="resultInput.length > 0"
                                    :href="useRoute('search.index', {q: resultInput})"
                                    class="absolute top-1/2 right-4 z-50 -translate-y-1/2 text-xs text-slate-900/75 hover:text-primary dark:text-white/75">
                                    See all results
                                </Link>
                            </div>
                            <div
                                v-if="loading"
                                class="justify-center p-4 text-center">
                                <Spinner class="mx-auto w-6 text-transparent" />
                            </div>
                            <div
                                v-else-if="error"
                                class="p-4 text-center text-sm text-red-500">
                                Error: {{ error }}
                            </div>
                            <div
                                v-else-if="resultInput.length > 0 && filteredResults.length === 0 && !loading"
                                class="break-all p-8 text-center">
                                No results for <em class="ml-1">&ldquo;{{ resultInput }}&rdquo;</em>
                            </div>
                            <div
                                v-else-if="resultInput.length === 0"
                                class="z-50 flex flex-col items-center gap-2 break-all p-8 text-center text-sm font-semibold text-slate-900/75 dark:text-white/75">
                                <Icon.DocumentSearch class="w-6" />
                                Search for articles and users
                            </div>
                            <div
                                v-else
                                class="max-h-[calc(100dvh-10rem)] overflow-auto py-2">
                                <div
                                    v-for="(result, index) in filteredResults"
                                    :key="result"
                                    class="mx-2 cursor-pointer"
                                    @mouseover="selectedResultIndex = index"
                                    @click="false ? selectResult(index) : ''">
                                    <Link
                                        v-if="result.type === 'user'"
                                        :href="result.url"
                                        :class="[{ 'bg-pink-light dark:bg-slate-800': index === selectedResultIndex }]"
                                        class="flex w-full items-center gap-4 rounded-lg p-2.5">
                                        <div class="w-10">
                                            <UserAvatar
                                                :user="result.data"
                                                class="rounded-full ring-1 ring-slate-900/10" />
                                        </div>
                                        <div class="flex flex-col">
                                            <div class="font-semibold font-heading">
                                                {{ result.data.name }}
                                            </div>
                                            <div class="-mt-1 text-xs text-slate-900/75 dark:text-white/75">
                                                {{ useFormatUsername(result.data) }}
                                            </div>
                                        </div>
                                    </Link>
                                    <Link
                                        v-else-if="result.type === 'article'"
                                        :href="result.url"
                                        :class="[{ 'bg-pink-light dark:bg-slate-800': index === selectedResultIndex }]"
                                        class="flex w-full flex-col items-stretch gap-4 rounded-lg p-2.5 md:flex-row">
                                        <div class="w-full md:w-1/4">
                                            <ArticleThumbnail
                                                :show-category="false"
                                                :article="result.data"
                                                :actions="false" />
                                        </div>
                                        <div class="flex w-full flex-col py-2 md:w-2/3">
                                            <h2 class="text-sm font-semibold line-clamp-2 font-heading group-hover:text-primary md:text-base/5">
                                                {{ result.data.title }}
                                            </h2>
                                            <p class="mt-1 text-xs line-clamp-2 md:text-sm">
                                                {{ result.data.excerpt }}
                                            </p>
                                            <ArticleUserLink
                                                :article="result.data"
                                                class="mt-4 md:mt-auto" />
                                        </div>
                                    </Link>

                                    <Link
                                        v-else-if="result.type === 'game'"
                                        :href="'#'"
                                        :class="[{ 'bg-pink-light dark:bg-slate-800': index === selectedResultIndex }]"
                                        class="flex w-full flex-col items-stretch gap-4 rounded-lg p-2.5 md:flex-row">
                                        <div class="w-full">
                                            <GameCardHorizontal
                                                :small="true"
                                                :inline="true"
                                                thumbnail-size="w-1/2 sm:w-1/4 md:w-1/6"
                                                :actions="false"
                                                :game="result.data" />
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </ModalContainer>
                </Transition.FadeUp>
            </div>
        </Transition>
    </teleport>
</template>
