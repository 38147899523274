<script setup>
import Button from '@/Components/UI/Button.vue'
import PushNotificationService from '@/Services/PwaNotificationsService.ts'
import { onMounted, ref } from 'vue'
import { Link } from '@inertiajs/vue3'
import { useTitle } from '@vueuse/core'
import { useRoute } from '@/Composables/useRoute.ts'
import { BellIcon } from '@heroicons/vue/24/outline'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'
import { BellIcon as SolidBellIcon } from '@heroicons/vue/24/solid'
import { useBroadcastNotifications } from '@/Composables/useBroadcastNotifications.ts'

const count = ref(usePageProps().auth.notifications)

function getNotificationsCount () {
    useMakeRequest(useRoute('api.notifications.count'), 'GET').then(response => {
        const number = response.unreadCount
        count.value = number

        setTimeout(() => {
            const originalTitle = usePageProps()?.meta?.title
            if (number > 0) {
                useTitle(`(${number}) ${originalTitle}`)
                if ('setAppBadge' in navigator) {
                    navigator.setAppBadge(number)
                }
            } else {
                useTitle(originalTitle)
                if ('setAppBadge' in navigator) {
                    navigator.clearAppBadge()
                }
            }
        }, 500)
    })
}

// When pusher receives a new notification
useBroadcastNotifications(() => getNotificationsCount())

// When pusher receives a notifications update
if (useIsAuthed() && window.Echo !== null) {
    window.Echo.private(`App.Models.User.${usePageProps().auth.user.id}`)
        .listen('Users.NotificationsUpdatedEvent', getNotificationsCount)
}

function requestPermission () {
    const pushService = new PushNotificationService()
    pushService.subscribeToPushNotifications()
}

onMounted(() => {
    getNotificationsCount()

    // 10 minutes
    setInterval(getNotificationsCount, 600000)
})
</script>

<template>
    <div class="relative flex">
        <div
            v-if="count > 0"
            class="absolute z-10 flex size-4 items-center justify-center rounded-full border-2 border-white text-xs text-white -right-1 -top-1 bg-primary" />
        <Button
            v-tooltip="`${(count ? `${count} ` : '')}Notifications`"
            :href="useRoute('dashboard.user.notifications')"
            :icon="BellIcon"
            variant="white"
            icon-only
            @click="requestPermission">
            <span class="sr-only">{{ count }} notifications</span>
        </Button>
    </div>
</template>
