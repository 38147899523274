import { usePageProps } from '@/Composables/usePageProps.ts'

export function useAnalyticsEvent (event: string, data: Record<string, any>): void {
    const pageProps = usePageProps()

    if (pageProps.analytics.enabled) {
        // eslint-disable-next-line no-undef
        pirsch(event, 1, data)
    }
}
