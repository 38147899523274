<script setup lang="ts">
import * as UserPermission from '@/Enums/UserPermission'
import Icon from '@/Components/Icon/index.js'
import Button from '@/Components/UI/Button.vue'
import FsLightbox from '@/Plugins/fslightbox/v3'
import BaseLayout from '@/Layouts/BaseLayout.vue'
import patternWhite from '~/app/patterns/white.png'
import UserAvatar from '@/Components/User/UserAvatar.vue'
import FollowButton from '@/Components/User/FollowButton.vue'
import { Link, router } from '@inertiajs/vue3'
import type { User } from '@/Types/Models/User'
import { usePlural } from '@/Composables/usePlural'
import { useRoute } from '@/Composables/useRoute.ts'
import { PencilIcon } from '@heroicons/vue/24/solid'
import { useIsAuthed } from '@/Composables/useIsAuthed'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { usePermissions } from '@/Composables/usePermissions'
import { useIsAuthedUser } from '@/Composables/useIsAuthedUser'
import { useFormatUsername } from '@/Composables/useFormatUsername'
import { useCountArticles } from '@/Composables/useCountArticles.ts'
import { useLinkifyString } from '@/Composables/useLinkifyString.ts'
import { useFollowerCount } from '@/Composables/useFollowerCount.ts'
import { useGetDomainFromUrl } from '@/Composables/useGetDomainFromUrl'
import { useGetFaviconFromUrl } from '@/Composables/useGetFaviconFromUrl.ts'
import { useArticleViewsCount } from '@/Composables/useArticleViewsCount.ts'
import { onBeforeUnmount, onMounted, type PropType, type Ref, ref } from 'vue'
import { DocumentTextIcon, EyeIcon, RectangleStackIcon, HomeIcon, ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/outline'

const props = defineProps({
    user: Object as PropType<User>,
    isFollowing: Boolean,
    isPrivate: Boolean,
    tab: String,
    followersCount: {
        type: Number,
        default: 0
    },
    initialArticleCount: {
        type: Number,
        default: null
    },
    initialFollowersCount: {
        type: Number,
        default: null
    }
})

const followerCount: Ref<number | null> = useFollowerCount(props.user, props.initialFollowersCount ?? 0)
const articleCount: Ref<number | null> = useCountArticles(props.user, props.initialArticleCount ?? 0)
const viewsCount: Ref<number | null> = useArticleViewsCount(props.user, props.initialArticleCount ?? 0)

const tabs = ref([
    {
        title: 'Home',
        key: 'home',
        active: false,
        icon: HomeIcon,
        link: useRoute('user.show', { user: props.user.username })
    },
    {
        title: 'Articles',
        key: 'articles',
        active: false,
        icon: DocumentTextIcon,
        link: useRoute('user.show.articles', { user: props.user.username })
    },
    {
        title: 'Posts',
        key: 'posts',
        active: false,
        icon: RectangleStackIcon,
        link: useRoute('user.show.posts', { user: props.user.username })
    }
])

function setActiveTab (key: string) {
    tabs.value.forEach((tab) => {
        tab.active = tab.key === key
    })
}

const showAvatarLightbox = ref<boolean>(false)

const bioClicked = ref<boolean>(false)
const showMore = ref<boolean>(false)

function cleanUrl (url: string): string {
    return url.replaceAll('https://', '').replaceAll('www.', '')
}

const isSticky = ref<boolean>(false)
const placeholderHeight = ref<string>('0px')
const coverTrigger = ref<HTMLElement | null>(null)
const stickyInfo = ref<HTMLElement | null>(null)
const infoPlaceholder = ref<HTMLElement | null>(null)
const navBarHeight = ref(0)

onMounted(() => {
    handleScroll()
    setActiveTab(props.tab)

    navBarHeight.value = document.getElementById('siteNav')?.offsetHeight ?? 0

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', updatePlaceholderHeight)
})

onBeforeUnmount(() => {
    window.removeEventListener('scroll', handleScroll)
    window.removeEventListener('resize', updatePlaceholderHeight)
})

function handleScroll () {
    const triggerElement = coverTrigger.value
    const stickyElement = stickyInfo.value
    const triggerPosition = triggerElement.getBoundingClientRect().bottom - navBarHeight.value

    if (triggerPosition <= 0) {
        isSticky.value = true
        placeholderHeight.value = `${stickyElement.offsetHeight}px`
    } else {
        isSticky.value = false
        placeholderHeight.value = '0px'
    }
}

function updatePlaceholderHeight () {
    if (isSticky.value) {
        const stickyElement = stickyInfo.value
        placeholderHeight.value = `${stickyElement.offsetHeight}px`
    }
}

router.on('navigate', () => {
    setActiveTab(props.tab)
})
</script>

<template>
    <BaseLayout>
        <FsLightbox
            v-if="user.avatars.full"
            :sources="[user.avatars.full]"
            :toggler="showAvatarLightbox" />
        <div class="fixed top-0 left-0 h-24 w-full bg-white" />
        <div class="mx-auto pb-12">
            <div class="mx-auto 8xl:rounded-b-2xl max-w-8xl">
                <div
                    ref="coverTrigger"
                    class="relative w-full cover-trigger bg-secondary aspect-[2/1] sm:aspect-[2.5/1] md:aspect-[3/1] lg:aspect-[4/1] xl:aspect-[4.5/1]">
                    <img
                        v-if="user.cover"
                        v-lazy="user.cover?.full"
                        :alt="`Cover image for ${user.name}`"
                        :src="user.cover?.small"
                        class="h-full w-full object-cover object-center">
                    <div
                        v-else
                        v-lazy:background="patternWhite"
                        class="absolute top-0 left-0 h-full w-full opacity-5 bg-[length:200px]" />
                </div>
                <div
                    ref="infoPlaceholder"
                    :style="{ height: placeholderHeight }"
                    class="info-placeholder" />
                <div
                    ref="stickyInfo"
                    class="z-30 h-12 w-full bg-white shadow-sm sticky-info md:h-14"
                    :style="{ top: navBarHeight + 'px' }"
                    :class="{ 'fixed': isSticky }">
                    <div class="mx-auto h-full max-w-7xl px-4">
                        <div class="flex h-full w-full items-center">
                            <div
                                :class="isSticky ? 'w-16 md:w-18' : 'w-28 md:w-64 '"
                                class="relative h-full transition-all">
                                <div
                                    :class="isSticky ? 'bottom-0 opacity-0' : 'opacity-100 -bottom-4 md:-bottom-8 shadow-sm'"
                                    class="absolute left-0 w-24 cursor-pointer rounded-full border-4 border-white bg-white transition-all md:w-48">
                                    <UserAvatar
                                        :user
                                        @click="showAvatarLightbox = !showAvatarLightbox" />
                                </div>

                                <div
                                    class="absolute bottom-0 left-0 aspect-square w-12 overflow-hidden transition-all md:w-14">
                                    <div
                                        :class="isSticky ? 'top-0' : 'top-full'"
                                        class="absolute left-0 cursor-pointer rounded-full border-4 border-white bg-white transition-all">
                                        <UserAvatar
                                            :user
                                            @click="showAvatarLightbox = !showAvatarLightbox" />
                                    </div>
                                </div>
                            </div>
                            <div class="h-full">
                                <ul class="flex h-full items-center text-sm -mx-2.5 md:-mx-4">
                                    <li
                                        v-for="singleTab in tabs"
                                        :key="singleTab.key"
                                        class="flex h-full">
                                        <Link
                                            preserve-scroll
                                            :href="singleTab.link"
                                            class="relative flex items-center px-2.5 group md:px-4"
                                            :class="[singleTab.active ? 'text-primary' : 'text-slate-900/75 hover:text-slate-900']"
                                            @click="setActiveTab(singleTab.key)">
                                            <div
                                                :class="[singleTab.active ? 'w-1/2 opacity-100' : 'w-1 group-hover:w-1/4 group-hover:opacity-10 opacity-0']"
                                                class="absolute bottom-0 left-1/2 z-0 h-1 -translate-x-1/2 rounded-t-full transition-all bg-primary" />
                                            <span class="z-10 flex items-center gap-2">
                                                <!--                                            <component-->
                                                <!--                                                :is="singleTab.icon"-->
                                                <!--                                                class="w-5" />-->
                                                {{ singleTab.title }}
                                            </span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div class="ml-auto hidden md:flex">
                                <FollowButton
                                    v-if="!useIsAuthed() || (useIsAuthed() && !useIsAuthedUser(user))"
                                    :key="'following-' + isFollowing"
                                    :following="isFollowing"
                                    :user="user" />

                                <Button
                                    v-if="useIsAuthedUser(user) && usePermissions(UserPermission.EDIT_OWN_USER)"
                                    :href="useRoute('dashboard.user.edit')"
                                    class="flex justify-center">
                                    <div class="mx-auto flex items-center space-x-2">
                                        <PencilIcon class="w-4" />
                                        <span>Edit profile</span>
                                    </div>
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mx-auto max-w-7xl px-4">
                <div class="flex w-full flex-col md:flex-row">
                    <article class="w-full shrink-0 pt-4 md:w-64 md:pt-8 md:pr-8">
                        <h1 class="text-3xl font-heading">
                            {{ user.name }}
                        </h1>
                        <h2 class="text-sm text-slate-900/75">
                            {{ useFormatUsername(user) }}
                        </h2>

                        <div class="flex items-center gap-4 mt-2.5">
                            <div class="flex items-center gap-1 text-sm text-slate-950/50">
                                <Icon.UserPlus class="w-5" />
                                <span>{{ followerCount }} {{ usePlural("follower", followerCount) }}</span>
                            </div>
                            <div class="flex items-center gap-1 text-sm text-slate-950/50">
                                <DocumentTextIcon class="h-4 w-4" />
                                <span>{{ articleCount }} {{ usePlural("article", articleCount) }}</span>
                            </div>
                        </div>

                        <div class="flex items-center gap-1 text-sm mt-2.5 text-slate-950/50">
                            <EyeIcon class="h-4 w-4" />
                            <span>{{ viewsCount }} {{ usePlural("view", viewsCount) }}</span>
                        </div>

                        <div class="mt-4 flex w-full md:hidden">
                            <FollowButton
                                v-if="!useIsAuthed() || (useIsAuthed() && !useIsAuthedUser(user))"
                                :key="'following-' + isFollowing"
                                class="w-full"
                                padding="py-1"
                                :following="isFollowing"
                                :user="user" />

                            <Button
                                v-if="useIsAuthedUser(user) && usePermissions(UserPermission.EDIT_OWN_USER)"
                                padding="py-1"
                                :href="useRoute('dashboard.user.edit')"
                                class="flex w-full justify-center">
                                <div class="mx-auto flex items-center space-x-2">
                                    <PencilIcon class="w-4" />
                                    <span>Edit profile</span>
                                </div>
                            </Button>
                        </div>

                        <div
                            v-if="user.bio || user.metadata.profile_links.length"
                            class="mt-2 flex md:hidden">
                            <button
                                class="flex w-full items-center justify-end gap-1 px-2 py-2 text-sm text-primary"
                                @click="showMore = !showMore">
                                <div>See {{ showMore ? "less" : "more" }}</div>
                                <ChevronDownIcon
                                    class="w-4 transition-all"
                                    :class="showMore ? 'rotate-180' : 'rotate-0'" />
                            </button>
                        </div>

                        <div
                            v-if="user.bio"
                            :class="showMore ? 'flex' : 'hidden md:flex'"
                            class="mt-4">
                            <p
                                itemprop="description"
                                :class="bioClicked ? '' : 'line-clamp-0 md:line-clamp-[4]'"
                                class="w-full text-sm text-slate-900/75 linkable"
                                @click="bioClicked = true"
                                v-html="useLinkifyString(user.bio)" />
                        </div>

                        <ul
                            :class="showMore ? 'flex' : 'hidden md:flex'"
                            class="mt-4 flex flex-col gap-1 text-inherit md:gap-1.5">
                            <li
                                v-for="link in user.metadata.profile_links"
                                :key="link.url">
                                <a
                                    :href="useRoute('link-out', {url :link.url, redirectToken: usePageProps().csrf_token})"
                                    itemprop="sameAs"
                                    rel="nofollow"
                                    class="flex items-center rounded px-1 text-slate-900/75 transition-all group py-1.5 hover:bg-primary/10 hover:text-primary"
                                    target="_blank">
                                    <div class="w-5 shrink-0">
                                        <Icon.TwitterX
                                            v-if="useGetDomainFromUrl(link.url) === 'twitter.com' || useGetDomainFromUrl(link.url) === 'x.com'"
                                            class="w-4"
                                        />
                                        <Icon.Facebook
                                            v-if="useGetDomainFromUrl(link.url) === 'facebook.com'"
                                            class="w-4"
                                        />
                                        <img
                                            v-if="['facebook.com', 'twitter.com', 'x.com'].every((social) => useGetDomainFromUrl(link.url) !== social)"
                                            :alt="`Webpage icon for ${cleanUrl(link.url)}`"
                                            :src="useGetFaviconFromUrl(link.url)"
                                            class="w-4 rounded-full"
                                            height="16"
                                            width="16">
                                    </div>
                                    <div
                                        class="block w-full truncate text-xs"
                                        v-html="link.label ? `${link.label}` : `${link.url}`" />
                                    <div class="ml-auto aspect-square w-5">
                                        <ArrowTopRightOnSquareIcon
                                            class="w-4 opacity-0 transition-all text-primary group-hover:opacity-100" />
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </article>
                    <div class="w-full pt-8">
                        <slot />
                    </div>
                </div>
            </div>
        </div>
    </BaseLayout>
</template>
