<script setup>
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter.js'
import InputTextarea from '@/Components/Input/InputTextarea.vue'
import MentionableTextarea from '@/Components/UI/MentionableTextarea.vue'
import { ref } from 'vue'
import { usePost } from '@/Composables/usePost.js'
import { useRoute } from '@/Composables/useRoute.ts'
import { useToast } from '@/Composables/useToast.ts'

const props = defineProps({
    url: String,
    parent: Object,
    formData: Object
})

const emit = defineEmits(['created'])
const focussed = ref(false)

const form = ref({
    content: null,
    parent_id: props.parent ? props.parent.id : null,
    commentable_id: props.formData.commentable_id,
    commentable_type: props.formData.commentable_type
})

function reset () {
    form.value.content = null
    useEmitter.emit('clearFileInputs')
}

function submit () {
    usePost({
        url: useRoute('comment.store'),
        data: form.value,
        onUnauthorized: () => useToast('Unauthorized action', 'fail'),
        onSuccess: response => {
            useEmitter.emit('created')
            useEmitter.emit('comment:created')
            form.value.content = null
            focussed.value = false
            emit('created')
        }
    })
}

function handleEnter () {
    if (!form.value.processing && (form.value.content)) {
        submit()
    }
}
</script>

<template>
    <div>
        <form @submit.prevent="submit">
            <div class="relative">
                <MentionableTextarea
                    v-model="form.content"
                    :error="form.errors?.content"
                    :textarea-class="focussed || form.content ? 'h-20' : 'h-12'"
                    placeholder="Add comment"
                    @blur="focussed = false"
                    @enter="handleEnter"
                    @focus="focussed = true"
                />
            </div>
            <div class="mt-2 flex w-full items-start justify-end">
                <div class="flex w-4/12 justify-end pt-2">
                    <Button
                        v-if="form.content"
                        class="mr-2"
                        type="button"
                        variant="white"
                        @click="reset">
                        Cancel
                    </Button>

                    <Button
                        :disabled="form.processing || (!form.content)"
                        type="submit"
                        variant="primary">
                        Create
                    </Button>
                </div>
            </div>
        </form>
    </div>
</template>
