<script lang="ts" setup>
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import TagList from '@/Components/Tag/TagList.vue'
import interactImage from '~/app/icons/interact.svg'
import GameGrid from '@/Components/Game/GameGrid.vue'
import WritingCta from '@/Components/Global/WritingCta.vue'
import EmptyState from '@/Components/Global/EmptyState.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import AdContainer from '@/Components/Global/AdContainer.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import GlobalBanner from '@/Components/Global/GlobalBanner.vue'
import WelcomeBanner from '@/Components/Global/WelcomeBanner.vue'
import AuthModalTrigger from '@/Components/Auth/AuthModalTrigger.vue'
import GameSkeletonGrid from '@/Components/Game/GameSkeletonGrid.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import SocialLinksMinimal from '@/Components/Global/SocialLinksMinimal.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import { onMounted, ref } from 'vue'
import type { Tag } from '@/Types/Models/Tag'
import type { Game } from '@/Types/Models/Game'
import { useRoute } from '@/Composables/useRoute.ts'
import type { Article } from '@/Types/Models/Article'
import { SparklesIcon } from '@heroicons/vue/24/solid'
import { ArrowRightIcon } from '@heroicons/vue/20/solid'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import type { ArticleGridList } from '@/Types/ArticleGridList'
import { useMakeRequest } from '@/Composables/useMakeRequest.js'
import { Deferred, Link, router, WhenVisible } from '@inertiajs/vue3'

const props = defineProps<{
    hero?: Article | null;
    bookmarksCount: number;

    counts: {
        articles: number;
        views: number;
        users: number;
    };

    latest: [ArticleGridList, null];
    recommended: [ArticleGridList, null];
    bookmarked: [ArticleGridList, null];
    onThisDay: [ArticleGridList, null];

    popularTags: [Tag[], null];
    trendingTopics:[Tag[], null];
    popularGames: [Game[], null];
}>()

const displayCounts = ref({
    articles: 0,
    views: 0,
    users: 0
})

onMounted(() => {
    router.reload({
        only: ['counts'],
        onSuccess: () => {
            displayCounts.value = props.counts
        }
    })
})
</script>

<template>
    <div>
        <GlobalBanner
            title="Account created"
            content="Visit your dashboard to get started" />

        <WelcomeBanner
            :articles-count="displayCounts.articles"
            :users-count="displayCounts.users"
            :views-count="displayCounts.views"
            class="m-4 mb-8 overflow-hidden rounded-2xl" />

        <div class="container mx-auto flex w-full flex-col">
            <div class="flex items-center justify-between">
                <SectionTitle>
                    Latest gaming articles
                </SectionTitle>
                <Button
                    :href="useRoute('articles.index')"
                    variant="text"
                    :icon="ArrowRightIcon">
                    See more
                </Button>
            </div>

            <Deferred data="latest">
                <template #fallback>
                    <ArticleSkeletonGrid
                        :contain="false"
                        :number="8" />
                </template>

                <ArticleGrid
                    :articles="latest"
                    :skeleton-count="3"
                    :allow-ad="true"
                    :include-writing-cta="true"
                    article-width="sm:w-1/2 lg:w-1/3 xl:w-1/4">
                    <template #empty>
                        <EmptyState
                            title="No articles"
                            description="Write your own and grow your very own community">
                            <template #button>
                                <div class="flex">
                                    <ArticleCreateButton />
                                </div>
                            </template>
                        </EmptyState>
                    </template>
                </ArticleGrid>
            </Deferred>
        </div>

        <div class="container mx-auto flex w-full flex-col items-stretch pb-12 md:flex-row">
            <div class="w-full md:w-8/12 xl:w-9/12">
                <div class="mb-12 w-full">
                    <WritingCta />
                </div>

                <SectionTitle v-if="useIsAuthed() && recommended">
                    For you
                </SectionTitle>

                <Deferred data="recommended">
                    <template #fallback>
                        <ArticleSkeletonGrid
                            :contain="false"
                            :number="8" />
                    </template>

                    <ArticleGrid
                        v-if="useIsAuthed() && recommended"
                        :articles="recommended"
                        :skeleton-count="3"
                        article-width="sm:w-1/2 lg:w-1/3">
                        <template #empty>
                            <EmptyState
                                :icon="interactImage"
                                :icon-width="96"
                                :icon-height="29"
                                title="No recommended articles"
                                description="Like, bookmark, and interact with articles to get recommendations based on your interests.">
                                <template #button>
                                    <div class="flex justify-center">
                                        <Button
                                            :href="useRoute('articles.index')"
                                            variant="primary">
                                            <span>Explore articles</span>
                                        </Button>
                                    </div>
                                </template>
                            </EmptyState>
                        </template>
                    </ArticleGrid>
                </Deferred>

                <div v-if="!useIsAuthed()">
                    <SectionTitle font-size="text-xl md:text-2xl">
                        <div class="flex items-center">
                            For you
                        </div>
                    </SectionTitle>
                    <EmptyState
                        :icon="interactImage"
                        title="Recommended articles"
                        description="Sign up for free to interact with articles, games, tags and discover more with smart recommendations.">
                        <template #button>
                            <div class="flex">
                                <AuthModalTrigger
                                    default-form="register">
                                    <template #trigger>
                                        <Button
                                            variant="primary">
                                            <span>Sign up here</span>
                                        </Button>
                                    </template>
                                </AuthModalTrigger>
                            </div>
                        </template>
                    </EmptyState>
                </div>

                <div class="mt-12">
                    <div class="flex items-center justify-between">
                        <SectionTitle>
                            Popular games
                        </SectionTitle>
                        <Button
                            :href="useRoute('games.index')"
                            variant="text"
                            :icon="ArrowRightIcon">
                            See more
                        </Button>
                    </div>
                    <Deferred data="recommended">
                        <template #fallback>
                            <GameSkeletonGrid
                                :number="4" />
                        </template>

                        <GameGrid
                            class="mt-2 -mx-1"
                            :skeleton-count="4"
                            :games="popularGames ? popularGames.slice(0, 4) : popularGames" />
                    </Deferred>
                </div>
            </div>
            <div class="relative flex w-full flex-col gap-8 pl-4 pb-8 md:w-4/12 xl:w-3/12">
                <Deferred data="popularTags">
                    <template #fallback />

                    <Card v-if="popularTags === null || popularTags.length > 0">
                        <TagList
                            :center="false"
                            :small="true"
                            :tags="popularTags"
                            :title="`Popular tags on ${usePageProps().app.name}`" />
                    </Card>
                </Deferred>

                <WhenVisible
                    data="trendingTopics"
                    :buffer="200">
                    <template #fallback />

                    <Card>
                        <TagList
                            :center="false"
                            :small="true"
                            title="Global trends"
                            description="These topics are sourced from multiple sources and summarised with AI."
                            :icon="SparklesIcon"
                            :tags="trendingTopics" />
                    </Card>
                </WhenVisible>

                <Deferred
                    v-if="bookmarksCount"
                    data="bookmarked">
                    <template #fallback>
                        <ArticleSkeletonGrid
                            :contain="false"
                            :horizontal="true"
                            :number="3" />
                    </template>

                    <Card>
                        <SectionTitle font-size="text-xl">
                            Bookmarked articles
                        </SectionTitle>
                        <ArticleGrid
                            :articles="bookmarked"
                            :horizontal="true">
                            <template #empty>
                                <SectionDescription class="mt-4 text-center">
                                    You don't have any bookmarked articles
                                </SectionDescription>
                                <div class="mt-2 mb-4 flex">
                                    <Button
                                        :href="useRoute('articles.index')"
                                        variant="primary"
                                        class="mx-auto">
                                        Explore articles
                                    </Button>
                                </div>
                            </template>
                        </ArticleGrid>
                        <div
                            v-if="bookmarked && bookmarked.length > 4"
                            class="mt-4 flex border-t pt-3 pl-2 border-primary/10">
                            <Button
                                :href="useRoute('articles.bookmarked')"
                                :icon="ArrowRightIcon"
                                variant="text">
                                View all
                            </Button>
                        </div>
                    </Card>
                </Deferred>

                <Deferred data="onThisDay">
                    <template #fallback />

                    <Card
                        v-if="onThisDay && onThisDay.length">
                        <SectionTitle font-size="text-xl">
                            On this day
                        </SectionTitle>
                        <SectionDescription>
                            Articles published on this date in previous years.
                        </SectionDescription>

                        <ArticleGrid
                            :articles="onThisDay"
                            :horizontal="true" />
                        <div class="mt-4 flex border-t pt-3 pl-2 border-primary/10">
                            <Button
                                :href="useRoute('articles.on-this-day')"
                                :icon="ArrowRightIcon"
                                variant="text">
                                View all
                            </Button>
                        </div>
                    </Card>
                </Deferred>

                <div class="top-20 flex w-full flex-col gap-8 md:sticky">
                    <AdContainer
                        ad-slot="homeSidebar"
                        padding="mx-2" />
                    <Card class="w-full">
                        <SectionTitle>Follow us</SectionTitle>
                        <SectionDescription>Connect with us and keep up to date on your favorite social media platforms.</SectionDescription>
                        <div class="my-5 md:mt-5 md:mb-2">
                            <SocialLinksMinimal />
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    </div>
</template>
