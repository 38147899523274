<script setup lang="ts">
import patternImage from '~/app/patterns/logo.svg'
import CategoryLink from '@/Components/Category/CategoryLink.vue'
import ArticleBookmarkButton from '@/Components/Article/ArticleBookmarkButton.vue'
import { Link } from '@inertiajs/vue3'
import { type PropType, ref } from 'vue'
import type { Article } from '@/Types/Models/Article'
import { ArchiveBoxIcon } from '@heroicons/vue/24/outline/index.js'

const props = defineProps({
    article: Object as PropType<Article>,
    size: {
        type: String,
        default: 'small'
    },
    actions: {
        type: Boolean,
        default: true
    },
    smallActions: {
        type: Boolean,
        default: false
    },
    loading: {
        type: String,
        default: 'lazy'
    },
    showCategory: {
        type: Boolean,
        default: true
    },
    useSrcSet: {
        type: Boolean,
        default: false
    },
    aspect: {
        type: String,
        default: 'aspect-article-thumb'
    },
    rounded: {
        type: String,
        default: 'rounded-xl md:rounded-xl'
    },
    urlOverride: [String, null]
})

const emit = defineEmits(['click'])

const isBookmarked = ref(props.article.bookmarked)
</script>

<template>
    <div
        :class="[rounded, aspect, ['large', 'full'].includes(size) ? 'bg-md lg:aspect-[16/9]' : 'bg-sm']"
        :style="`background-image:url('${patternImage}')`"
        class="relative w-full overflow-hidden bg-center bg-repeat"
        @click="actions ? null : emit('click')">
        <Link
            v-if="actions"
            prefetch
            :href="urlOverride ? urlOverride : article.permalink"
            class="absolute bottom-0 left-0 z-10 h-full w-full opacity-0 peer">
            (link to article)
        </Link>

        <div
            v-if="article.featured_image"
            class="h-full w-full bg-transparent peer-focus:bg-white transition-all ease-nice group-hover:bg-white group-focus:bg-white">
            <img
                itemprop="image"
                :alt="`Thumbnail image for ${article.title}`"
                :src="article.featured_image[size + '_webp']"
                :srcset="
                    `${article.featured_image.full_webp} 1920w,
                ${article.featured_image.large_webp} 1024w,
                ${article.featured_image.medium_webp} 768w,
                ${article.featured_image.small_webp} 500w`"
                :sizes="`(max-width: 1024px) 100vw, (max-width: 1920px) 50vw, 1920px`"
                class="my-0 h-full w-full object-cover object-center peer-focus:opacity-90 transition-all ease-nice group-hover:opacity-90 group-focus:opacity-90">
        </div>

        <div
            v-if="showCategory"
            :class="smallActions ? 'left-1.5 top-1.5' : 'left-2.5 top-2.5'"
            class="absolute z-20 flex items-center gap-2">
            <CategoryLink
                v-if="article.category"
                :category="article.category"
                :size="size" />

            <div
                v-if="article.isArchive"
                class="flex select-none items-center rounded-full bg-black py-1 text-xs text-white transition-colors px-2.5 space-x-1">
                <ArchiveBoxIcon class="w-4" />
                <div>Archive</div>
            </div>
        </div>

        <div
            v-if="actions"
            :class="[isBookmarked ? 'md:opacity-100' : 'md:opacity-30', smallActions ? 'scale-75 left-1 top-1' : 'right-2.5 top-2.5']"
            class="absolute z-10 opacity-100 transition-all group-hover:opacity-100 group-focus:opacity-100">
            <ArticleBookmarkButton
                :article="article"
                small
                :bookmarked="isBookmarked"
                @bookmarked="isBookmarked = true"
                @unbookmarked="isBookmarked = false" />
        </div>
    </div>
</template>
