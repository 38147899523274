<script setup lang="ts">
import Logo from '@/Components/Logo'
import Button from '@/Components/UI/Button.vue'
import Novus from '@/Components/Global/Novus.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import { computed, ref } from 'vue'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { usePlural } from '@/Composables/usePlural'
import { useRoute } from '@/Composables/useRoute.ts'
import { ArrowRightIcon } from '@heroicons/vue/24/solid'
import { useIsAuthed } from '@/Composables/useIsAuthed.js'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useGetSetting } from '@/Composables/useGetSetting.js'
import { useFormatNumber } from '@/Composables/useFormatNumber.ts'
import { useUpdateSetting } from '@/Composables/useUpdateSetting.js'

const props = defineProps<{
    articlesCount: number;
    usersCount:number;
    viewsCount: number;
}>()

const show = ref(true)

const updateSetting = useUpdateSetting()

if (useIsAuthed()) {
    show.value = !useGetSetting('welcome_banner_closed')
} else {
    show.value = localStorage.getItem('welcome_banner_closed') === null
}

function closeBanner () {
    if (useIsAuthed()) {
        updateSetting('welcome_banner_closed', true)
    } else {
        localStorage.setItem('welcome_banner_closed', JSON.stringify(true))
    }

    show.value = false
}

const stats = computed(() => {
    return {
        user: props.usersCount,
        view: props.viewsCount,
        article: props.articlesCount
    }
})
</script>

<template>
    <div v-if="show">
        <div
            class="relative isolate overflow-hidden bg-gradient-to-t px-6 bg-gradient-app sm:px-16 lg:flex lg:gap-x-20 lg:px-24">
            <button
                v-tooltip="'Dismiss'"
                class="absolute top-5 right-6 z-20 text-white/50 transition-colors hover:text-white"
                @click="closeBanner">
                <span class="sr-only">Dismiss welcome banner</span>
                <XMarkIcon class="w-8 md:w-6" />
            </button>

            <div class="relative z-10 mx-auto flex w-full flex-col justify-center py-20 text-center lg:py-32 lg:text-left">
                <h1 class="text-4xl tracking-tight text-white font-heading sm:text-6xl">
                    Welcome to {{ usePageProps().app.name }}
                </h1>
                <p class="mx-auto mt-4 max-w-lg text-lg leading-8 text-white lg:mx-0">
                    Discover gaming articles, engage in the community, write your own articles, and more!
                </p>
                <div class="mt-6 flex xxs:flex-row flex-col items-center justify-center gap-6 lg:justify-start">
                    <ArticleCreateButton
                        text="Write an article"
                        variant="white" />
                    <Button
                        variant="ghost"
                        :icon="ArrowRightIcon"
                        :href="useRoute('about-us')">
                        Learn more
                    </Button>
                </div>
                <div class="mt-12 flex xxs:flex-row flex-col items-center justify-center gap-6 lg:justify-start">
                    <div class="flex w-full max-w-md items-center text-white">
                        <div
                            v-for="(stat, title) in stats"
                            :key="title"
                            class="flex w-1/3 flex-col text-center md:text-left">
                            <div class="text-3xl font-semibold font-heading sm:text-4xl">
                                {{ useFormatNumber(stat) }}
                            </div>
                            <div class="mt-2">
                                Total {{ usePlural(title, stat) }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--            <div class="absolute -bottom-px left-1/2 z-0 flex h-full flex-col justify-end lg:h-[90%]">-->
            <!--                <img-->
            <!--                    :src="HeroImage"-->
            <!--                    fetchpriority="high"-->
            <!--                    width="723"-->
            <!--                    height="615"-->
            <!--                    class="z-20 hidden w-full max-w-xl lg:flex xl:h-full xl:w-auto xl:max-w-none lg:dark:hidden"-->
            <!--                    alt="Article editor screenshot (desktop)">-->
            <!--                <img-->
            <!--                    :src="HeroImageDark"-->
            <!--                    fetchpriority="high"-->
            <!--                    width="723"-->
            <!--                    height="615"-->
            <!--                    class="z-20 hidden w-full max-w-xl xl:h-full xl:w-auto xl:max-w-none lg:dark:flex"-->
            <!--                    alt="Article editor screenshot (desktop - dark mode)">-->
            <!--            </div>-->

            <!--            <img-->
            <!--                :src="HeroImage"-->
            <!--                fetchpriority="low"-->
            <!--                width="325"-->
            <!--                height="275"-->
            <!--                class="-bottom-1 mx-auto flex w-full dark:hidden lg:hidden"-->
            <!--                alt="Article editor screenshot (mobile)"-->
            <!--            >-->
            <!--            <img-->
            <!--                :src="HeroImageDark"-->
            <!--                fetchpriority="low"-->
            <!--                width="325"-->
            <!--                height="275"-->
            <!--                class="-bottom-1 mx-auto hidden w-full dark:flex lg:hidden lg:dark:hidden"-->
            <!--                alt="Article editor screenshot (mobile - dark mode)"-->
            <!--            >-->

            <Logo.MarkWhite
                class="absolute right-0 -bottom-56 -z-10 translate-x-1/3 opacity-50 h-128 dark:opacity-5 md:h-[40rem] md:bottom-2" />
            <Logo.MarkWhite
                class="absolute bottom-0 left-0 hidden w-44 -translate-x-4 translate-y-1/4 opacity-20 dark:opacity-5 md:block" />

            <Novus
                :emotion="'excited'"
                class="lg:absolute mx-auto right-0 xl:right-12 -bottom-4 w-full max-w-3xl md:max-w-2xl 2xl:max-w-3xl" />
        </div>
    </div>
</template>
