<script setup>
import Card from '@/Components/UI/Card.vue'
import Button from '@/Components/UI/Button.vue'
import useEmitter from '@/Composables/useEmitter'
import ErrorText from '@/Components/UI/ErrorText.vue'
import InputText from '@/Components/Input/InputText.vue'
import SectionTitle from '@/Components/UI/SectionTitle.vue'
import DashboardLayout from '@/Layouts/DashboardLayout.vue'
import InputTextarea from '@/Components/Input/InputTextarea.vue'
import UserCoverForm from '@/Components/User/Form/UserCoverForm.vue'
import UserAvatarForm from '@/Components/User/Form/UserAvatarForm.vue'
import DashboardTitle from '@/Components/Dashboard/DashboardTitle.vue'
import ManageConsoles from '@/Components/User/Form/ManageConsoles.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import ManageProfileLinks from '@/Components/User/Form/ManageProfileLinks.vue'
import ManageProfileSections from '@/Components/User/Form/ManageProfileSections.vue'
import { useForm, Link } from '@inertiajs/vue3'
import { useRoute } from '@/Composables/useRoute.ts'
import { usePageProps } from '@/Composables/usePageProps.ts'
import { useAnalyticsEvent } from '@/Composables/useAnalyticsEvent.ts'

const props = defineProps({
    user: Object,
    oauth: Boolean,
    hasArticles: Boolean,
    badges: Array,
    availableProfileSections: Array,
    profileLinksLimit: Number,
    profileTagsLimit: Number,
    profileBadgeLimit: Number,
    tags: Array,
    email_verified: Boolean
})

const form = useForm({
    name: props.user.name,
    username: props.user.username,
    email: props.user.email,
    bio: props.user.bio ? props.user.bio : '',
    metadata: {
        profile_links: props.user.metadata?.profile_links,
        profile_badges: props.user.metadata?.profile_badges,
        profile_tags: props.user.metadata?.profile_tags,
        profile_sections: props.user.metadata?.profile_sections,
        consoles: props.user.metadata?.consoles
    },
    avatar: props.user.avatars?.original,
    avatar_changed: false,

    cover: props.user.cover?.original,
    cover_changed: false
})

const resetAvatar = () => {
    form.avatar = props.user.avatars.original
    form.avatar_changed = false
}

const resetCover = () => {
    form.cover = props.user.cover?.original
    form.cover_changed = false
}

const removeCover = () => {
    form.cover = null
    form.cover_changed = true
}

const addAvatar = (file) => {
    form.avatar = file
    form.avatar_changed = true
}

const addCover = (file) => {
    form.cover = file
    form.cover_changed = true
}

function reset () {
    form.reset()
    form.clearErrors()
    useEmitter.emit('clearFileInputs')
}

function submit () {
    form.patch(useRoute('user.update', { user: props.user.username }), {
        preserveScroll: true
        // errorBag: 'updateProfileInformation'
    })
    form.avatar_changed = false
    form.cover_changed = false

    useAnalyticsEvent('Update profile')
}

defineOptions({
    layout: (h, page) => h(DashboardLayout, {}, () => [page]),
    inheritAttrs: false
})
</script>

<template>
    <div class="pb-24">
        <DashboardTitle
            title="Edit profile"
            :description="`Edit and manage your ${usePageProps().app.name} profile`" />
        <div class="relative mx-auto flex max-w-3xl flex-col">
            <form
                class="flex flex-col gap-8"
                @submit.prevent="submit">
                <Card
                    padding="p-0"
                    class="overflow-hidden">
                    <div class="flex w-full flex-col">
                        <div class="w-full border-b border-slate-950/10">
                            <UserCoverForm
                                :default-cover="form.cover"
                                :removable="form.cover_changed && form.cover !== null"
                                @deleted="removeCover"
                                @removed="resetCover"
                                @selected="addCover" />
                        </div>
                        <div class="w-full p-3 pt-2 md:p-5">
                            <SectionTitle font-size="text-xl">
                                Cover image
                            </SectionTitle>
                            <SectionDescription>
                                Recommended size: 1800 x 450
                            </SectionDescription>
                        </div>
                    </div>
                    <div class="flex w-full items-center gap-4 p-3 pt-0 md:p-5 md:pt-0">
                        <div class="aspect-square w-24">
                            <UserAvatarForm
                                :default-avatar="user.avatars.medium"
                                :removable="form.avatar_changed"
                                @removed="resetAvatar"
                                @selected="addAvatar" />
                        </div>
                        <div>
                            <SectionTitle font-size="text-xl">
                                Avatar
                            </SectionTitle>
                            <SectionDescription>
                                Recommended size: 256 x 256
                            </SectionDescription>
                        </div>
                    </div>
                </Card>
                <Card>
                    <SectionTitle
                        font-size="text-xl">
                        Personal information
                    </SectionTitle>
                    <SectionDescription>
                        Update your personal info and bio to let others know who you are and more about you.
                    </SectionDescription>
                    <div class="mt-4 flex flex-col space-y-4">
                        <div class="flex flex-col gap-4 sm:flex-row">
                            <InputText
                                v-model="form.name"
                                :error="form.errors.name"
                                class="w-full"
                                label="Name" />
                            <InputText
                                v-model="form.username"
                                :error="form.errors.username"
                                class="w-full"
                                label="Username" />
                        </div>
                        <InputText
                            v-model="form.email"
                            :error="form.errors.email"
                            label="Email address" />
                        <div
                            v-if="!email_verified"
                            class="flex justify-between rounded-md bg-green-200 text-sm text-slate-900 p-2.5">
                            Email verification has been sent
                            <Link
                                :href="useRoute('verification.send')"
                                as="button"
                                class="rounded-sm border border-green-500 bg-green-500 px-2 text-xs text-white"
                                method="post"
                                preserve-scroll
                                preserve-state
                                type="button">
                                Resend
                            </Link>
                        </div>

                        <InputTextarea
                            v-model="form.bio"
                            :autoheight="true"
                            :error="form.errors.bio"
                            :max="240"
                            :show-counter="true"
                            label="Bio" />
                    </div>
                </Card>

                <Card>
                    <SectionTitle
                        font-size="text-xl">
                        Profile links
                    </SectionTitle>
                    <SectionDescription>
                        Display links to your social media profiles and other websites.
                    </SectionDescription>
                    <ManageProfileLinks
                        :key="form.metadata.profile_links"
                        v-model="form.metadata.profile_links"
                        class="mt-4"
                        :errors="form.errors"
                        :limit="profileLinksLimit" />
                </Card>

                <Card>
                    <SectionTitle
                        font-size="text-xl">
                        Profile Sections
                    </SectionTitle>
                    <SectionDescription>
                        Rename, toggle, and reorder the sections on your profile.
                    </SectionDescription>
                    <ManageProfileSections
                        :key="form.metadata.profile_sections"
                        v-model="form.metadata.profile_sections"
                        :available-profile-sections="availableProfileSections"
                        class="mt-4" />
                    <ErrorText
                        :error="form.errors['metadata.profile_sections']"
                        class="mt-2" />
                </Card>

                <Card>
                    <SectionTitle
                        font-size="text-xl">
                        Consoles
                    </SectionTitle>
                    <SectionDescription>
                        Add your favourite or most used consoles to your profile.
                    </SectionDescription>
                    <ManageConsoles
                        :key="form.metadata.consoles"
                        v-model="form.metadata.consoles"
                        class="mt-4" />
                    <ErrorText
                        :error="form.errors['metadata.consoles']"
                        class="mt-2" />
                </Card>
                <!--                <Card :class="highlightedSectionEnabled ? 'opacity-100 pointer-events-auto' : 'opacity-50 pointer-events-none'">-->
                <!--                    <SectionTitle-->
                <!--                        font-size="text-xl">-->
                <!--                        Highlighted tags-->
                <!--                    </SectionTitle>-->
                <!--                    <SectionDescription>-->
                <!--                        Highlight your favourite or most used tags on your profile.-->
                <!--                    </SectionDescription>-->
                <!--                    <ManageProfileTags-->
                <!--                        :key="form.metadata.profile_tags"-->
                <!--                        v-model="form.metadata.profile_tags"-->
                <!--                        class="mt-4"-->
                <!--                        :available-tags="tags"-->
                <!--                        :limit="profileTagsLimit" />-->
                <!--                    <ErrorText-->
                <!--                        :error="form.errors['metadata.profile_tags']"-->
                <!--                        class="mt-2" />-->
                <!--                </Card>-->
            </form>

            <div
                :class="form.isDirty ? 'flex' : 'hidden'"
                class="sticky bottom-2 mt-4 flex w-full justify-end gap-4 overflow-hidden rounded-2xl border bg-white p-4 transition-all border-primary/10 dark:bg-slate-800">
                <Button
                    :disabled="!form.isDirty"
                    class="px-8"
                    type="button"
                    variant="white"
                    @click="reset">
                    {{ form.processing ? 'Resetting' : 'Reset' }}
                </Button>
                <Button
                    :disabled="!form.isDirty"
                    class="px-8"
                    type="submit"
                    @click="submit">
                    {{ form.processing ? 'Saving' : 'Save' }}
                </Button>
            </div>
        </div>
    </div>
</template>
