<script setup lang="ts">
import CalendarImage from '~/app/decoration/calendar.svg'
import EmptyState from '@/Components/Global/EmptyState.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import ArticleSkeletonGrid from '@/Components/Article/ArticleSkeletonGrid.vue'
import ArticleCreateButton from '@/Components/Article/ArticleCreateButton.vue'
import { router } from '@inertiajs/vue3'
import { type PropType, ref } from 'vue'
import type { PageHeader } from '@/Types/PageHeader'
import type { Article } from '@/Types/Models/Article'
import { CalendarIcon } from '@heroicons/vue/24/solid'

type ArticlesByYear = {
    [year: number]: Article[];
};

const props = defineProps({
    articles: Object as PropType<ArticlesByYear>,
    header: Object as PropType<PageHeader>,
    date: String,
    lazy: {
        type: Boolean,
        default: false
    },
    minCount: {
        type: Number,
        default: 8
    },
    paginated: {
        type: Boolean,
        default: true
    }
})

const allArticles = ref(null)
const years = ref([])

function lazyload () {
    const load = []
    if (props.lazy) {
        load.push('articles')
    }

    if (load.length) {
        router.reload({
            only: load,
            onSuccess: () => {
                allArticles.value = props.articles
                years.value = Object.keys(props.articles)
            }
        })
    }
}

router.on('navigate', lazyload)

function scrollToYear (year: number) {
    const element = document.getElementById('year' + year)
    if (element) {
        element.scrollIntoView({
            behavior: 'smooth'
        })
    }
}

defineOptions({
    inheritAttrs: false
})
</script>

<template>
    <div class="pb-12">
        <div>
            <div class="relative py-12">
                <div class="container relative mx-auto">
                    <div class="flex items-start gap-4 sm:items-center md:gap-8">
                        <div class="aspect-square w-12 sm:w-24 md:w-36">
                            <img
                                v-lazy="CalendarImage"
                                :alt="header.title + ' ' + date + ' calendar image'"
                                class="h-full">
                        </div>
                        <div class="relative flex flex-col items-start">
                            <div class="relative z-10 text-3xl leading-tight font-heading md:text-5xl">
                                {{ header.title }}
                            </div>
                            <div class="relative z-10 text-lg font-bold md:text-2xl">
                                {{ date }}
                            </div>
                            <div
                                v-if="years.length > 0"
                                class="absolute top-full left-0 z-10 flex gap-2 pt-2 text-2xl">
                                <a
                                    v-for="year in years"
                                    :key="year"
                                    :href="'#year' + year"
                                    class="flex select-none items-center rounded-full bg-white px-3 py-1 text-xs ring-1 ring-slate-900/10 transition-all hover:bg-slate-900 hover:text-white hover:ring-slate-900 dark:bg-slate-700 dark:hover:bg-slate-950"
                                    @click.prevent="scrollToYear(year)">
                                    {{ year }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--        {{ allArticles }}-->

        <div
            v-if="allArticles && allArticles.length < 1"
            class="container mx-auto">
            <EmptyState
                :icon="CalendarIcon"
                title="No articles for this date"
                description="Try writing your own and check back next year!">
                <template #button>
                    <div class="flex">
                        <ArticleCreateButton />
                    </div>
                </template>
            </EmptyState>
        </div>

        <div class="mt-4 flex flex-col gap-24 md:mt-12">
            <ArticleSkeletonGrid
                v-if="!allArticles"
                :has-description="false"
                :number="4" />
            <div
                v-for="(articleItems, year, index) in allArticles"
                :id="'year' + year"
                :key="year"
                class="relative"
                style="scroll-margin-top: 80px;">
                <div
                    v-if="index > 0"
                    class="absolute bottom-full left-1/2 h-24 w-px -translate-x-1/2 bg-current opacity-10 text-primary">
                    <div class="absolute top-0 left-1/2 -translate-x-1/2">
                        <div class="aspect-square w-3 rounded-full bg-current" />
                    </div>
                    <div class="absolute bottom-0 left-1/2 -translate-x-1/2">
                        <div class="aspect-square w-3 rounded-full bg-current" />
                    </div>
                </div>

                <ArticleGrid
                    contain
                    :title="year"
                    :skeleton-count="minCount"
                    :articles="articleItems"
                    class="mt-0" />
            </div>
        </div>
    </div>
</template>
