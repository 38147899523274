<script setup lang="ts">
import Pagination from '@/Components/UI/Pagination.vue'
import ProfileLayout from '@/Layouts/ProfileLayout.vue'
import ArticleGrid from '@/Components/Article/ArticleGrid.vue'
import SectionDescription from '@/Components/UI/SectionDescription.vue'
import { ref } from 'vue'
import type { Article } from '@/Types/Models/Article.js'
import { useHasPagination } from '@/Composables/useHasPagination.js'

defineProps({
    user: Object,
    articleLimit: Number,
    hasArticles: Boolean,
    articles: Object,
    taggedArticles: Object
})

const articlesContainer = ref(null)

defineOptions({ layout: ProfileLayout })
</script>

<template>
    <div>
        <div ref="articlesContainer">
            <div
                v-if="!hasArticles"
                class="pt-24 pb-12">
                <div class="container mx-auto text-center">
                    <SectionDescription>
                        {{ user.name }} has not published any articles yet.
                    </SectionDescription>
                </div>
            </div>
            <ArticleGrid
                v-if="hasArticles"
                article-width="md:w-1/2 lg:w-1/3"
                :skeleton-count="articleLimit"
                :articles="articles.data"
                title="All gaming articles" />
            <SectionDescription
                v-if="articles.data.length < 1"
                class="my-8 text-center">
                There's nothing here
            </SectionDescription>
            <Pagination
                v-if="useHasPagination<Article>(articles)"
                :show-count="true"
                preserve-scroll
                :data="articles" />
        </div>

        <div
            v-for="data in taggedArticles"
            :key="data.tag.id">
            <div>
                <ArticleGrid
                    :articles="data.articles.data"
                    article-width="md:w-1/2 lg:w-1/3"
                    :title="data.tag.name + ' articles'"
                    class="mt-12" />
                <SectionDescription
                    v-if="data.articles.data.length < 1"
                    class="my-8 text-center">
                    There's nothing here
                </SectionDescription>
                <Pagination
                    class="px-4"
                    :show-count="true"
                    :links="data.articles"
                    preserve-scroll
                />
            </div>
        </div>
    </div>
</template>
